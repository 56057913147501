@font-face {
  font-family: "DungeonFont";
  src: url("assets/NotoMono-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: none;
}

/* nova-mono-regular - latin */
@font-face {
  font-family: "DungeonFontX";
  font-style: normal;
  font-weight: 400;
  src: local("NovaMono"),
    url("assets/nova-mono-v10-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/nova-mono-v10-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/*@font-face {
  font-family: "DungeonFont";
  src: url("assets/NotoSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}*/

@font-face {
  font-family: "ConsoleFont";
  src: url("assets/Hack-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ConsoleFont";
  src: url("assets/Hack-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "ConsoleFont";
  src: url("assets/Hack-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

.label {
  grid-row: 1/2;
  grid-column: 2;
  background-color: #efe0b9 !important;
  padding: 10px 20px 0px !important;
  color: black !important;
  cursor: pointer !important;
  span {
    a {
      color: #1200ff;
    }
    float: right;
  }
}

.statusField .changed {
  color: black !important;
  background-color: white !important;
}

.console {
  grid-column: 2;
  grid-row: 3/4;
  width: 100%;
  font-size: smaller;
  border-top-left-radius: 8px;
  scroll-behavior: smooth;
  overflow: hidden;
  position: relative;
  bottom: 0;
  font-family: ConsoleFont;
  #input::before {
    content: ">";
  }

  #input {
    border-top: 1pt dashed bisque;
  }
}

.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100fr 1fr;
  grid-template-rows: 1fr 1fr 1000fr;
  background-color: #efe0b9;
  color: #444;
}

.box {
  background-color: #000;
  color: #fff;

  padding: 20px;
  //font-size: 150%;
}

.map {
  grid-column: 1 / 2;
  grid-row: 1/4;
  position: relative;
}

.statusField {
  text-align: center;
}

.status .debug {
  font-size: smaller;
  order: 999;
}

#feedbackSubmitButton:focus {
  outline: 1px dotted #efe0b9;
  outline-offset: 1px;
}

#output {
  margin-bottom: 1px;
}

.status {
  font-family: ConsoleFont;
  grid-column: 2/3;
  grid-row: 2 / 3;
  width: 40em;
  font-size: smaller;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
}

.tooltip {
  padding-left: 1em;
  width: 90%;
}

#debug {
  position: absolute;
  background-color: rgba(70, 130, 180, 0.5);
  font-size: small;
  bottom: 0;
  right: 0;
  display: block;
  padding: 1px 8px;
  color: lightsteelblue;
}
